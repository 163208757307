<template>
  <div>
    <Nav :header="header"></Nav>

    <v-container>
      <h2
        class="brade-session"
        style="color: #e5173f; font-size: 24px; font-weight: 900"
        v-if="loteEmDisputa"
      >
        <span class="brade-titulo-oferta"
          >ANÚNCIO {{ loteEmDisputa.LoteNumero }} EM DISPUTA</span
        >
      </h2>
      <v-col v-if="loteEmDisputa" cols="12">
        <v-row style="display: flex; align-items: stretch">
          <v-col cols="12" class="brade-line">
            <v-card
              class="brade-card"
              max-width="344"
              style="margin: 10px"
              min-width="344"
              elevation="10"
              shaped
            >
              <div
                class="ribbon-wrapper"
                v-if="loteEmDisputa.MsgLote === 'TEMPO ESGOTANDO !'"
              >
                <div class="glow">&nbsp;</div>
                <div class="ribbon-front">{{ loteEmDisputa.MsgLote }}</div>
              </div>
              <div
                class="ribbon-wrapper"
                v-if="loteEmDisputa.MsgLote === 'TEMPO EXTRA!'"
              >
                <div class="glow">&nbsp;</div>
                <div class="ribbon-front">{{ loteEmDisputa.MsgLote }}</div>
              </div>

              <v-img
                :eager="true"
                :src="
                  loteEmDisputa
                    ? loteEmDisputa.FotoCard
                    : 'http://cdn.vipleiloes.com/wac/vipleiloes/nao-disponivel.png'
                "
                height="194"
                style="cursor: pointer"
              ></v-img>
              <v-list-item>
                <v-col cols="12">
                  <v-row justify="center">
                    <h3 class="text-center">{{ loteEmDisputa.Titulo }}</h3>
                  </v-row>

                  <v-row justify="center"
                    ><p class="text-center">
                      {{ loteEmDisputa.Descricao1 }}
                    </p>
                  </v-row>
                  <v-row justify="center">
                    <!-- <v-divider class="brade-divider"></v-divider> -->
                    <v-progress-linear
                      :value="loteEmDisputa.Progresso"
                      height="10"
                      class="brade-divider"
                      color="red"
                      rounded
                      reverse
                    >
                      <!-- <template v-slot:default="{ value }">
                        <strong>{{ Math.ceil(value) }}%</strong>
                      </template> -->
                    </v-progress-linear>
                  </v-row>
                  <v-row justify="center">
                    <h3 class="subtitle-1 text-center">Valor atual</h3>
                  </v-row>
                  <v-row justify="center">
                    <h1 class="text-center" style="color: #e5173f">
                      {{
                        loteEmDisputa.VencendoValor === "R$ 0,00"
                          ? loteEmDisputa.ValorInicial
                          : loteEmDisputa.VencendoValor
                      }}
                    </h1>
                  </v-row>

                  <v-row justify="center" align-content="center">
                    <p class="text-center">
                      <v-icon
                        large
                        :color="isTimeEncerrando ? 'yellow' : 'red'"
                        :class="isTimeEncerrando ? 'tocando-timer' : ''"
                        >mdi-timer-outline</v-icon
                      ><span>Este Anúncio se encerra em </span>
                    </p>
                  </v-row>
                  <v-row justify="center" align-content="center">
                    <p class="text-center">
                      <span>
                        <b>{{ loteEmDisputa.Tempo }}</b>
                      </span>
                    </p>
                  </v-row>
                </v-col>
              </v-list-item>

              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-icon color="error" right>mdi-map-marker</v-icon>
                    {{
                      loteEmDisputa.ExpostoCidade
                        ? loteEmDisputa.ExpostoCidade
                        : "Sem infos"
                    }}
                    -
                    {{ loteEmDisputa.ExpostoUF }}
                  </v-col>

                  <v-col cols="6">
                    <v-icon color="error" right>mdi-bag-suitcase</v-icon
                    ><b style="padding-right: 2px"
                      >{{ loteEmDisputa.VencendoNumero }}
                    </b>
                    {{ configLeilao.TermoQtd }}(s)
                  </v-col>
                </v-row>
              </v-col>
              <v-card-actions>
                <p
                  class="caption text-left"
                  v-if="loteEmDisputa.Tempo !== '00:00:00'"
                >
                  {{ configLeilao.TermoLeilao }}:
                  <span>{{ configLeilao.LeilaoData }}</span>
                </p>
                <v-chip class="ma-2" color="error" text-color="white" v-else>
                  Encerrado
                  <v-icon right>mdi-close-circle</v-icon>
                </v-chip>
                <v-spacer></v-spacer>

                <p
                  class="caption text-left"
                  v-if="loteEmDisputa.Tempo !== '00:00:00'"
                >
                  {{ configLeilao.TermoLance }}
                  <span class="pl-2">{{ loteEmDisputa.LoteNumero }}</span>
                </p>
              </v-card-actions>
            </v-card>

            <!-- detalhes do lote em diputa -->

            <v-card
              style="margin: 10px"
              class="modal-brade-disputa"
              v-if="loteEmDisputa"
            >
              <v-toolbar flat color="error" dark>
                <v-toolbar-title
                  >{{ configLeilao.TermoLance }} {{ loteEmDisputa.LoteNumero }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-tabs v-model="active_tab" color="error" :centered="true">
                <v-tab>
                  <v-icon key="Inicio" left>mdi-comment-text</v-icon>Inicio
                </v-tab>
                <v-tab
                  @click="
                    carregaInfosLote(
                      loteEmDisputa.LoteCod,
                      loteEmDisputa.LoteHash
                    )
                  "
                >
                  <v-icon key="informações" left>mdi-book-search</v-icon
                  >Informações
                </v-tab>
                <v-tab>
                  <v-icon key="Fotos" left>mdi-image-area</v-icon>Fotos
                </v-tab>
                <v-tab>
                  <v-icon key="Video" left>mdi-youtube-play</v-icon>Video
                </v-tab>
                <v-tab>
                  <v-icon key="Visitacao" left>mdi-loupe</v-icon>Visitação
                  virtual
                </v-tab>
                <v-tab
                  key="Ofertas"
                  @click="
                    getLances(loteEmDisputa.LoteCod, loteEmDisputa.LoteHash)
                  "
                >
                  <v-icon left>mdi-account-multiple</v-icon
                  >{{ configLeilao.TermoQtd }}
                </v-tab>

                <v-tab-item key="Inicio">
                  <v-card flat>
                    <v-card-text>
                      <h3 class="text-left">{{ loteEmDisputa.Titulo }}</h3>
                      <!-- <div class="text-left">
                        Por: {{ loteEmDisputa.Comitente || "Não cadastrado" }}
                      </div> -->

                      <v-col cols="12">
                        <v-row>
                          <!-- descricao minima do lote -->
                          <v-col
                            class="brade-left"
                            style="max-width: 30.333333%"
                            cols="4"
                          >
                            <p class="subtitle-1">
                              {{ configLeilao.TermoQtd }} atual de
                              {{ loteEmDisputa.LanceTotal }}
                            </p>
                            <v-alert color="light-green" colored-border>
                              <h3>
                                {{
                                  loteEmDisputa.VencendoValor === "R$ 0,00"
                                    ? loteEmDisputa.ValorInicial
                                    : loteEmDisputa.VencendoValor
                                }}
                                {{ configLeilao.Comissao }}
                              </h3>
                            </v-alert>
                            <p>
                              <span>{{ configLeilao.TermoQtd }} Inicial:</span>
                              {{ loteEmDisputa.ValorInicial }}
                            </p>
                            <p v-if="loteEmDisputa.ValorMercado">
                              <span>Valor de Mercado: </span>
                              <!-- {{ loteEmDisputa.ValorMercado }} -->
                              <money
                                value
                                v-model="loteEmDisputa.ValorMercado"
                                v-bind="money"
                              ></money>
                            </p>
                            <hr />
                            <p v-html="loteEmDisputa.Descricao2"></p>
                          </v-col>
                          <!-- botoes de lance -->

                          <v-col
                            class="brade-right"
                            style="margin-right: 10px"
                            cols="4"
                          >
                            <p
                              v-if="isHabilitado"
                              class="text-center"
                              style="font-weight: bold"
                            >
                              {{
                                loteEmDisputa.Tempo !== "00:00:00"
                                  ? `Enviar ${configLeilao.TermoQtd}`
                                  : `${loteEmDisputa.MsgLote}`
                              }}
                            </p>
                            <div v-if="isHabilitado">
                              <v-row
                                justify="center"
                                v-if="loteEmDisputa.Tempo !== '00:00:00'"
                              >
                                <div class="subheading mx-3">
                                  <v-btn
                                    @click="
                                      DarLance(
                                        loteEmDisputa,
                                        loteEmDisputa.Botao1
                                      )
                                    "
                                    color="#EF5350"
                                    :loading="loadingBtn"
                                    :disabled="loadingBtn"
                                    ><span style="color: white">{{
                                      loteEmDisputa.Botao1
                                    }}</span></v-btn
                                  >
                                </div>
                                <div class="subheading mx-3">
                                  <v-btn
                                    @click="
                                      DarLance(
                                        loteEmDisputa,
                                        loteEmDisputa.Botao2
                                      )
                                    "
                                    :loading="loadingBtn"
                                    :disabled="loadingBtn"
                                    color="#D32F2F"
                                    ><span style="color: white">{{
                                      loteEmDisputa.Botao2
                                    }}</span>
                                  </v-btn>
                                </div>
                                <div class="subheading mx-3">
                                  <v-btn
                                    @click="
                                      DarLance(
                                        loteEmDisputa,
                                        loteEmDisputa.Botao3
                                      )
                                    "
                                    :loading="loadingBtn"
                                    :disabled="loadingBtn"
                                    color="#D32F2F"
                                    ><span style="color: white">{{
                                      loteEmDisputa.Botao3
                                    }}</span>
                                  </v-btn>
                                </div>
                                <br />
                                <div
                                  v-if="isHabilitado"
                                  class="subheading mx-3"
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div
                                        v-if="
                                          loteEmDisputa &&
                                          loteEmDisputa.LanceAutomatico !== ''
                                        "
                                        style="display: flex"
                                      >
                                        <v-btn
                                          class="text-center"
                                          @click="
                                            processarLanceAutomatico(
                                              loteEmDisputa,
                                              0
                                            )
                                          "
                                          color="#EF5350"
                                          ><span style="color: #e5173f"
                                            >Cancelar</span
                                          ></v-btn
                                        >

                                        <v-icon
                                          style="margin-left: 2px"
                                          color="white"
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          mdi-information-outline
                                        </v-icon>
                                      </div>
                                    </template>
                                    <span>
                                      Cancelará sua oferta automático
                                      <strong>cadastrada</strong> de
                                      <money
                                        value
                                        v-model="loteEmDisputa.LanceAutomatico"
                                        v-bind="money"
                                      ></money
                                    ></span>
                                  </v-tooltip>
                                </div>

                                <!-- <v-alert
                                  v-if="loteEmDisputa.LanceAutomatico"
                                  dense
                                  type="info"
                                >
                                  Cancelará sua oferta automático
                                  <strong>cadastrada</strong>
                                </v-alert> -->
                              </v-row>
                            </div>
                            <div v-else>
                              <v-row justify="center">
                                <div class="subheading mx-3 text-center">
                                  <span style="color: white"
                                    ><h2>
                                      <v-icon color="white"
                                        >mdi-eye-settings</v-icon
                                      >&nbsp; Espectador
                                    </h2></span
                                  >
                                </div>
                                <div v-if="loteEmDisputa.VencendoApelido">
                                  <div class="subheading mx-3 text-center">
                                    <p color="#D32F2F">
                                      <span style="color: white">{{
                                        "Vencendo"
                                      }}</span>
                                    </p>
                                  </div>
                                  <div class="text-center">
                                    <p color="#D32F2F">
                                      <span style="color: white">{{
                                        loteEmDisputa.VencendoApelido
                                      }}</span>
                                    </p>
                                  </div>
                                </div>
                              </v-row>
                            </div>
                          </v-col>
                          <!-- Times  e vencendo lote -->

                          <v-col class="brade-timer" cols="4">
                            <!-- timer -->
                            <div class="brade-right">
                              <p class="text-center" style="font-weight: bold">
                                <v-icon
                                  large
                                  :color="isTimeEncerrando ? 'yellow' : 'white'"
                                  :class="
                                    isTimeEncerrando
                                      ? 'text-center tocando-timer'
                                      : 'center'
                                  "
                                  >mdi-timer</v-icon
                                >
                              </p>
                              <v-row
                                justify="center"
                                v-if="loteEmDisputa.Tempo !== '00:00:00'"
                              >
                                <div class="subheading mx-3">
                                  <h4 class="white--text text-center">
                                    Termina em
                                  </h4>
                                  <h4 class="white--text text-center">
                                    {{ loteEmDisputa.Tempo }}
                                  </h4>
                                </div>
                              </v-row>
                            </div>
                            <!-- vencendo texto -->
                            <v-row
                              style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin: 5px;
                              "
                            >
                              <h3>VENCENDO</h3>
                            </v-row>
                            <!-- nome vencendo -->
                            <v-row
                              style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin: 5px;
                              "
                            >
                              <h2>
                                {{ loteEmDisputa.VencendoApelido }}
                              </h2>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!-- tab de informacoes -->
                <v-tab-item key="informações">
                  <v-card
                    class="mx-auto"
                    flat
                    max-height="550"
                    style="overflow-y: scroll; padding: 20px"
                  >
                    <div v-if="dadosInfoLote">
                      <v-card-title>
                        <h3>
                          <!-- {{ dadosInfoLote.SubClasseNome }} -->
                          {{ dadosInfoLote.EstoqueVeiculoMarca }}
                          {{ dadosInfoLote.EstoqueVeiculoModelo }}
                          {{ dadosInfoLote.EstoqueVeiculoAno }}/{{
                            dadosInfoLote.EstoqueVeiculoAnoMod
                          }}
                        </h3>
                        <!-- <p>
                          Por: {{ loteEmDisputa.Comitente || "Não cadastrado" }}
                        </p> -->
                      </v-card-title>

                      <!-- <v-card-text> -->
                      <!-- <v-container class="py-0" fluid> -->
                      <div
                        class="text-h2"
                        v-if="dadosInfoLote.DocumentoLaudoCautelarArquivo"
                      >
                        <p class="brade-title">Laudo cautelar</p>
                        <v-btn
                          :href="dadosInfoLote.DocumentoLaudoCautelarArquivo"
                          target="_blank"
                          style="margin-bottom: 10px"
                          color="#0D47A1"
                          depressed
                          class="white--text"
                        >
                          Download do laudo cautelar
                        </v-btn>
                      </div>
                      <!-- </v-container> -->
                    </div>
                    <br />

                    <p class="brade-title py-0">Informações do item</p>
                    <v-row dense>
                      <v-col style="align-items: flex-start">
                        <v-card>
                          <!-- <v-card-title class="text-h5"
                            >Informações do item</v-card-title
                          > -->
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <b>Categoria: </b>{{ dadosInfoLote.SubClasseNome }}
                          </div>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <b>Marca: </b
                            >{{ dadosInfoLote.EstoqueVeiculoMarca }}
                          </div>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <b>Modelo: </b
                            >{{ dadosInfoLote.EstoqueVeiculoModelo }}
                          </div>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <b>Ano/Ano modelo: </b
                            >{{ dadosInfoLote.EstoqueVeiculoAno }}/{{
                              dadosInfoLote.EstoqueVeiculoAnoMod
                            }}
                          </div>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col
                        class="brade-col-right"
                        style="align-items: flex-start"
                      >
                        <v-card>
                          <!-- <div class="my-4 text-subtitle-1 brade-descricao">
                                <b>Direção: </b
                                >{{
                                  dadosInfoLote.EstoqueVeiculoDirecaoHidraulicaNome
                                }}
                              </div> -->
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <b>Final da placa: </b
                            >{{ dadosInfoLote.EstoqueVeiculoPlacaFinal }}
                          </div>

                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <b>Localização: </b>
                            <!-- {{ dadosInfoLote.EstoqueExpostoemCidade }} -
                            {{ dadosInfoLote.EstoqueExpostoemUF }}
                            {{ dadosInfoLote.EstoqueExpostoemEndereco }}
                            {{ dadosInfoLote.EstoqueExpostoemEnderecoBairro }}
                            {{ dadosInfoLote.EstoqueExpostoemEnderecoCEP }} -->

                            {{ dadosInfoLote.EstoqueExpostoemEndereco }}
                            {{ dadosInfoLote.EstoqueExpostoemEnderecoBairro }}
                            {{ dadosInfoLote.EstoqueExpostoemCidade }} -
                            {{ dadosInfoLote.EstoqueExpostoemUF }}
                            {{ dadosInfoLote.EstoqueExpostoemEnderecoCEP }}
                          </div>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                    <p class="brade-title">Detalhes do item</p>
                    <v-row dense>
                      <v-col style="align-items: flex-start">
                        <v-card>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <b>Câmbio: </b
                            >{{ dadosInfoLote.EstoqueVeiculoCambioNome }}
                          </div>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <b>Cor: </b>{{ dadosInfoLote.EstoqueVeiculoCor }}
                          </div>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <b>Km: </b>{{ dadosInfoLote.EstoqueVeiculoKm }}
                          </div>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <b>Vidro elétrico: </b
                            >{{ dadosInfoLote.EstoqueVeiculoVidroEletricoNome }}
                          </div>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <b>Trava: </b
                            >{{ dadosInfoLote.EstoqueVeiculoTravaEletricaNome }}
                          </div>
                        </v-card>
                      </v-col>
                      <v-col
                        class="brade-col-right"
                        style="align-items: flex-start"
                      >
                        <v-card>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <b>Direção: </b
                            >{{
                              dadosInfoLote.EstoqueVeiculoDirecaoHidraulicaNome
                            }}
                          </div>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <b>Espelho eletrico: </b
                            >{{ dadosInfoLote.EstoqueVeiculoVidroEletricoNome }}
                          </div>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <b>Combustível: </b
                            >{{ dadosInfoLote.EstoqueVeiculoCombustivel }}
                          </div>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <b>Ar condicionado: </b
                            >{{ dadosInfoLote.EstoqueVeiculoArcondicionalNome }}
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <p class="brade-title">Informações importantes</p>
                    <v-row dense>
                      <v-col style="align-items: flex-start">
                        <v-card>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <p style="text-align: justify">
                              Eventual divergência no padrão de placas Mercosul
                              no físico ou no documento do veículo (ATPVe), a
                              responsabilidade pela regularização será do
                              comprador, bem como eventuais pagamentos de taxas
                              e encargos exigidos na troca de placas. Ocorrendo
                              multa de averbação na transferência de documentos,
                              esta será por conta do comprador.
                            </p>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col
                        class="brade-col-right"
                        style="align-items: flex-start"
                      >
                        <v-card>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <!-- <p style="text-align: justify">
                              Visitação: {{ dadosInfoLote.Visitacao }}
                            </p> -->

                            <p
                              style="
                                text-align: justify;
                                font-size: 16px;
                                color: #444444;
                              "
                              v-html="configLeilao.Visitacao"
                            ></p>
                            <p
                              style="
                                text-align: justify;
                                font-size: 16px;
                                color: #444444;
                              "
                            >
                              Local -
                              {{ dadosInfoLote.EstoqueExpostoemCidade }} -
                              {{ dadosInfoLote.EstoqueExpostoemUF }}
                              {{ dadosInfoLote.EstoqueExpostoemEndereco }}
                              {{ dadosInfoLote.EstoqueExpostoemEnderecoBairro }}
                              {{ dadosInfoLote.EstoqueExpostoemEnderecoCEP }}
                            </p>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <p class="brade-title">Forma de Pagamento</p>
                    <v-row dense>
                      <v-col style="align-items: flex-start">
                        <v-card>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <p style="text-align: justify">
                              Através de Transferência Eletrônica, devendo ser
                              obrigatoriamente transferido pela conta de
                              propriedade do CNPJ do lojista cadastrado. A conta
                              de crédito para pagamento será em nome da Carbuy
                              Automotive Business Ltda. Prazo para pagamento é
                              até o dia
                              {{ dadosInfoLote.PrazoPagamento }}.
                            </p>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row v-if="dadosInfoLote.EstoqueObservacaoVendedor" dense>
                      <v-col style="align-items: flex-start">
                        <v-card>
                          <p class="brade-title">Observações do veículo</p>
                          <v-row dense>
                            <v-col style="align-items: flex-start">
                              <v-card>
                                <div
                                  class="my-4 text-subtitle-1 brade-descricao"
                                >
                                  {{ dadosInfoLote.EstoqueObservacaoVendedor }}
                                </div>
                              </v-card>
                            </v-col>
                          </v-row>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <!-- <v-col style="align-items: flex-start">
                            <v-card>
                              <p class="brade-title">Endereço</p>
                              <v-row dense>
                                <v-col style="align-items: flex-start">
                                  <v-card>
                                    <div
                                      class="
                                        my-4
                                        text-subtitle-1
                                        brade-descricao
                                      "
                                    >
                                      {{
                                        dadosInfoLote.EstoqueExpostoemEndereco
                                      }}
                                      {{
                                        dadosInfoLote.EstoqueExpostoemEnderecoBairro
                                      }}
                                      {{
                                        dadosInfoLote.EstoqueExpostoemEnderecoCEP
                                      }}
                                      {{ dadosInfoLote.EstoqueExpostoemUF }}
                                    </div>
                                  </v-card>
                                </v-col>
                              </v-row>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-col> -->
                    </v-row>

                    <!-- <div v-else>Não possui informações</div> -->
                  </v-card>
                </v-tab-item>
                <!-- tab de fotos -->
                <v-tab-item key="Fotos">
                  <v-card class="mx-auto" flat max-width="650">
                    <v-card-text>
                      <v-carousel
                        v-if="loteEmDisputa.FotosModal"
                        hide-delimiters
                      >
                        <v-carousel-item
                          v-for="(img, index) in loteEmDisputa.FotosModal"
                          :key="index"
                          :src="img.FotoURL"
                          delimiter-icon="mdi-minus"
                        ></v-carousel-item>
                      </v-carousel>
                      <!-- <p v-else>Não encontado fotos</p> -->
                      <div
                        v-else
                        style="
                          text-align: center;
                          margin: auto;
                          margin-top: 50px;
                        "
                      >
                        <h3>Não possui fotos</h3>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!-- tab de video -->
                <v-tab-item key="Video">
                  <v-card class="mx-auto" flat>
                    <v-card-text>
                      <div
                        style="display: flex; justify-content: center"
                        v-if="loteEmDisputa.CodigoYoutube"
                        v-html="loteEmDisputa.CodigoYoutube"
                      ></div>
                      <!-- <p v-else>Não encontado fotos</p> -->
                      <div
                        v-else
                        style="
                          text-align: center;
                          margin: auto;
                          margin-top: 50px;
                        "
                      >
                        <h3>Não possui Video</h3>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!-- tab de visitacao -->
                <v-tab-item key="Visitacao">
                  <v-card class="mx-auto" flat>
                    <v-card-text>
                      <div
                        v-if="loteEmDisputa.CodigoVirtual"
                        v-html="loteEmDisputa.CodigoVirtual"
                      ></div>
                      <!-- <p v-else>Não encontado fotos</p> -->
                      <div
                        v-else
                        style="
                          text-align: center;
                          margin: auto;
                          margin-top: 50px;
                        "
                      >
                        <h3>Não possui visitação virtual</h3>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!-- tab de lances -->
                <v-tab-item key="Ofertas">
                  <v-card flat>
                    <v-card-text>
                      <v-simple-table fixed-header height="300px">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">#</th>
                              <th class="text-left">Nome</th>
                              <th class="text-left">UF</th>
                              <th class="text-left">Valor</th>
                              <th class="text-left">Data</th>
                            </tr>
                          </thead>
                          <tbody v-if="listLance.length">
                            <tr
                              v-for="(lance, index) in listLance"
                              :key="index"
                            >
                              <td>{{ lance.NumeroLance }}</td>
                              <td>{{ lance.Apelido }}</td>
                              <td>{{ lance.PessoaUF }}</td>
                              <td>{{ lance.ValorLance }}</td>
                              <td>{{ lance.DataLance }}</td>
                            </tr>
                          </tbody>
                          <tfoot v-else>
                            <tr>
                              <td colspan="5" style="text-align: center">
                                <h4>Nenhum item encontrado</h4>
                              </td>
                            </tr>
                          </tfoot>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- <hr v-if="listaProximosLotes.length" /> -->
      <h2
        class="brade-session"
        style="color: #e5173f; font-size: 24px; font-weight: 900"
      >
        <span class="brade-titulo-x2">
          PRÓXIMOS ANÚNCIOS {{ listaProximosLotes.length }}</span
        >
      </h2>

      <v-col v-if="listaProximosLotes.length" cols="12">
        <v-row style="display: flex; align-items: stretch">
          <v-col cols="12" style="display: flex; flex-wrap: wrap">
            <v-card
              @click="carregaModal(lote)"
              max-width="344"
              class="brade-card"
              v-for="(lote, index) in listaProximosLotes"
              :key="index"
              style="margin: 10px"
              min-width="344"
              elevation="10"
              shaped
            >
              <div
                class="ribbon-wrapper"
                v-if="lote.LoteSituacao === 'REPASSE'"
              >
                <div class="glow">&nbsp;</div>
                <div class="ribbon-front">PRÓXIMA OFERTA</div>
              </div>

              <v-img
                :eager="true"
                :src="
                  lote
                    ? lote.FotoCard
                    : 'http://cdn.vipleiloes.com/wac/vipleiloes/nao-disponivel.png'
                "
                height="250"
              ></v-img>
              <v-list-item>
                <v-col cols="12">
                  <v-row justify="center">
                    <h3 class="text-center">{{ lote.Titulo }}</h3>
                  </v-row>

                  <v-row justify="center"
                    ><p class="text-center">
                      {{ lote.Descricao1 }}
                    </p>
                  </v-row>
                  <v-row justify="center">
                    <!-- <v-divider
                      class="brade-divider"
                      style="border-width: 4px !important; max-width: 50%"
                    ></v-divider> -->
                    <v-progress-linear
                      :value="0"
                      style="border-width: 4px !important; max-width: 50%"
                      height="10"
                      class="brade-divider"
                      color="red"
                      rounded
                      reverse
                    ></v-progress-linear>
                  </v-row>
                  <v-row justify="center">
                    <h3 class="subtitle-1 text-center">Valor atual</h3>
                  </v-row>
                  <v-row justify="center">
                    <h1 class="text-center" style="color: #e5173f">
                      {{
                        lote.VencendoValor === "R$ 0,00"
                          ? lote.ValorInicial
                          : lote.VencendoValor
                      }}
                    </h1>
                  </v-row>

                  <v-row justify="center" align-content="center">
                    <p class="text-center">
                      <v-icon large color="red">mdi-timer-outline</v-icon
                      ><span
                        >Este {{ configLeilao.TermoLance }} se encerra em
                        <b>{{ lote.Tempo }}</b>
                      </span>
                    </p>
                  </v-row>
                </v-col>
              </v-list-item>
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-icon color="error" right>mdi-map-marker</v-icon>
                    {{ lote.ExpostoCidade ? lote.ExpostoCidade : "Sem infos" }}
                    -
                    {{ lote.ExpostoUF }}
                  </v-col>

                  <v-col cols="6">
                    <v-icon color="error" right>mdi-bag-suitcase</v-icon
                    ><b>
                      {{ lote.VencendoNumero }}
                    </b>
                    {{ configLeilao.TermoQtd }}(s)
                  </v-col>
                </v-row>
              </v-col>

              <v-card-actions>
                <p class="caption text-left">
                  <span>{{ configLeilao.LeilaoData }}</span>
                </p>
                <v-spacer></v-spacer>

                <p class="caption text-left">
                  {{ configLeilao.TermoLance }}
                  <span class="pl-2">{{ lote.LoteNumero }}</span>
                </p>
              </v-card-actions>
              <v-chip class="ma-2" color="error" text-color="white">
                Aguardando
                <v-icon right>mdi-close-circle</v-icon>
              </v-chip>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <h2
        class="brade-session"
        style="color: #e5173f; font-size: 24px; font-weight: 900"
      >
        <span class="brade-titulo-x2"
          >ENCERRADOS {{ listaLoteEncerradoVencedores.length }}</span
        >
      </h2>

      <v-col v-if="listaLoteEncerradoVencedores.length" cols="12">
        <v-row style="display: flex; align-items: stretch">
          <v-col cols="12" style="display: flex; flex-wrap: wrap">
            <v-card
              max-width="344"
              class="brade-card"
              v-for="(lote, index) in listaLoteEncerradoVencedores"
              :key="index"
              style="margin: 10px; cursor: pointer"
              min-width="344"
              @click="carregaModal(lote)"
              :disabled="desabilitaModalEncerrado"
              elevation="10"
              shaped
            >
              <div
                class="ribbon-wrapper"
                v-if="lote.LoteSituacao === 'ENCERRADO'"
              >
                <div class="glow">&nbsp;</div>
                <div class="ribbon-front">
                  {{
                    lote.VencendoApelido
                      ? "VENCEDOR " + lote.VencendoApelido
                      : "SEM OFERTAS"
                  }}
                </div>
              </div>

              <v-img
                :eager="true"
                :src="
                  lote
                    ? lote.FotoCard
                    : 'http://cdn.vipleiloes.com/wac/vipleiloes/nao-disponivel.png'
                "
                height="250"
              ></v-img>

              <v-list-item>
                <v-col cols="12">
                  <v-row justify="center">
                    <h3 class="text-center">{{ lote.Titulo }}</h3>
                  </v-row>

                  <v-row justify="center"
                    ><p class="text-center">
                      {{ lote.Descricao1 }}
                    </p>
                  </v-row>
                  <v-row justify="center">
                    <!-- <v-divider
                      class="brade-divider"
                      style="border-width: 4px !important; max-width: 50%"
                    ></v-divider> -->
                    <v-progress-linear
                      :value="0"
                      style="border-width: 4px !important; max-width: 50%"
                      height="10"
                      class="brade-divider"
                      color="red"
                      rounded
                      reverse
                    ></v-progress-linear>
                  </v-row>
                  <v-row justify="center">
                    <h3 class="subtitle-1 text-center">Valor atual</h3>
                  </v-row>
                  <v-row justify="center">
                    <h1 class="text-center" style="color: #e5173f">
                      {{
                        lote.VencendoValor === "R$ 0,00"
                          ? lote.ValorInicial
                          : lote.VencendoValor
                      }}
                    </h1>
                  </v-row>

                  <v-row justify="center" align-content="center">
                    <p class="text-center">
                      <v-icon large color="red">mdi-timer-outline</v-icon
                      ><span
                        >Este {{ configLeilao.TermoLance }} se encerra em
                        <b>{{ lote.Tempo }}</b>
                      </span>
                    </p>
                  </v-row>
                </v-col>
              </v-list-item>

              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-icon color="error" right>mdi-map-marker</v-icon>
                    {{ lote.ExpostoCidade ? lote.ExpostoCidade : "Sem infos" }}
                    -
                    {{ lote.ExpostoUF }}
                  </v-col>

                  <v-col cols="6">
                    <v-icon color="error" right>mdi-bag-suitcase</v-icon
                    ><b>
                      {{ lote.VencendoNumero }}
                    </b>
                    {{ configLeilao.TermoQtd }}(s)
                  </v-col>
                </v-row>
              </v-col>

              <v-card-actions>
                <p class="caption text-left">
                  <span>{{ configLeilao.LeilaoData }}</span>
                </p>
                <v-spacer></v-spacer>

                <p class="caption text-left">
                  {{ configLeilao.TermoLance }}
                  <span class="pl-2">{{ lote.LoteNumero }}</span>
                </p>
              </v-card-actions>
              <v-chip class="ma-2" color="error" text-color="white">
                {{
                  lote.VencendoApelido
                    ? "VENCEDOR " + lote.VencendoApelido
                    : "SEM OFERTAS"
                }}
                <v-icon right>mdi-close-circle</v-icon>
              </v-chip>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- modal do lote proximo -->

      <v-dialog
        v-model="dialog"
        persistent
        :max-width="isMobile() ? '600px' : '1360px'"
        v-if="loteModal"
      >
        <v-card>
          <v-toolbar
            flat
            :color="corPorEmpresaCardHeader(configLeilao.Empresa)"
            dark
          >
            <v-toolbar-title
              >{{ configLeilao.TermoLance }} {{ loteModal.LoteNumero }}
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-icon color="white">mdi-timer</v-icon>
            Termina em {{ loteModal.Tempo }}

            <v-btn
              :color="corPorEmpresaCardHeader(configLeilao.Empresa)"
              @click="fechaModal()"
              ><v-icon large> mdi-close </v-icon></v-btn
            >
          </v-toolbar>
          <v-tabs
            v-model="active_tabProximo"
            :color="corPorEmpresaCardHeader(configLeilao.Empresa)"
            :centered="true"
          >
            <v-tab>
              <v-icon key="InicioProximo" left>mdi-comment-text</v-icon>Inicio
            </v-tab>
            <v-tab
              v-if="
                configLeilao.Empresa &&
                configLeilao.Empresa.toLowerCase() === 'carbuy'
              "
              @click="
                carregaInfosLoteProximo(loteModal.LoteCod, loteModal.LoteHash)
              "
            >
              <v-icon key="informacoesProximo" left>mdi-book-search</v-icon
              >Informações
            </v-tab>

            <v-tab>
              <v-icon key="VideoProximo" left>mdi-youtube-play</v-icon>Video
            </v-tab>
            <!-- <v-tab>
              <v-icon key="Visitacao_virtual_proximo" left>mdi-loupe</v-icon
              >Visitação virtual
            </v-tab> -->
            <v-tab>
              <v-icon key="FotosProximo" left>mdi-image-area</v-icon>Fotos
            </v-tab>
            <v-tab
              key="OfertasProximo"
              @click="getLances(loteModal.LoteCod, loteModal.LoteHash)"
            >
              <v-icon left>mdi-account-multiple</v-icon
              >{{ configLeilao.TermoQtd }}
            </v-tab>

            <v-tab-item key="InicioProximo">
              <v-card flat>
                <v-card-text :class="isMobile() ? '' : 'd-flex'">
                  <v-col :cols="isMobile() ? '12' : '6'">
                    <h3
                      class="text-left"
                      style="
                        display: flex;
                        align-items: center;
                        padding-bottom: 5px;
                        justify-content: space-between;
                      "
                    >
                      {{ loteModal.Titulo }}
                      <v-btn
                        small
                        icon
                        @click="dialogFotos = true"
                        color="pink"
                      >
                        <v-icon>mdi-image-search</v-icon>
                      </v-btn>
                    </h3>

                    <div class="d-flex flex-no-wrap justify-space-between">
                      <p
                        v-if="loteModal.CodigoVirtual"
                        v-html="loteModal.CodigoVirtual"
                        class="text-center"
                        style="margin: auto"
                      ></p>
                    </div>
                  </v-col>

                  <v-col :cols="isMobile() ? '12' : '6'">
                    <v-row>
                      <v-col class="brade-left py-0" cols="6">
                        <p class="subtitle-1">
                          {{ configLeilao.TermoQtd }} atual de
                          {{ loteModal.LanceTotal }}
                        </p>
                        <v-icon>mdi-alert-circle-outline</v-icon
                        ><i style="font-size: 11px"
                          >Este é o valor à pagar, não existem taxas adicionais,
                          exceto custo de frete, caso contratado.</i
                        >
                        <v-alert color="light-green" colored-border>
                          <h3>
                            {{
                              loteModal.VencendoValor === "R$ 0,00"
                                ? loteModal.ValorInicial
                                : loteModal.VencendoValor
                            }}

                            {{ configLeilao.Comissao }}
                          </h3>
                        </v-alert>
                        <p>
                          <span>{{ configLeilao.TermoQtd }} Inicial:</span>
                          {{ loteModal.ValorInicial }}
                        </p>
                        <p v-if="loteModal.ValorMercado">
                          <span>Valor de Mercado: </span>
                          <!-- {{ loteModal.ValorMercado }} -->
                          <money
                            value
                            v-model="loteModal.ValorMercado"
                            v-bind="money"
                          ></money>
                        </p>
                        <hr />
                        <p v-html="loteModal.Descricao2"></p>
                        <b v-if="loteModal.ObservacaoVendedor"
                          >Observação do veículo:</b
                        >
                        {{ loteModal.ObservacaoVendedor }} <br />
                      </v-col>
                      <v-col class="py-0" :cols="isMobile() ? '12' : '6'">
                        <div class="brade-right" v-if="isHabilitado">
                          <div
                            class="subheading mx-3"
                            style="width: 90% !important"
                          >
                            <v-row justify="center">
                              <div v-if="loteModal.VencendoApelido">
                                <div class="subheading mx-3 text-center">
                                  <p color="#D32F2F">
                                    <span style="color: white">{{
                                      loteModal.LoteSituacao !== "ENCERRADO"
                                        ? "Vencendo"
                                        : "Vencedor"
                                    }}</span>
                                  </p>
                                </div>
                                <div class="text-center">
                                  <p color="#D32F2F">
                                    <span style="color: white">{{
                                      loteModal.VencendoApelido
                                    }}</span>
                                  </p>
                                </div>
                              </div>
                            </v-row>
                          </div>
                        </div>
                        <div class="brade-right" style="margin-top: 20px">
                          <p
                            v-if="isHabilitado"
                            class="text-center"
                            style="font-weight: bold"
                          >
                            {{
                              loteModal.Tempo !== "00:00:00"
                                ? `Enviar ${configLeilao.TermoQtd}`
                                : `${loteModal.MsgLote}`
                            }}
                          </p>
                          <div v-if="isHabilitado">
                            <v-row
                              v-if="
                                loteModal.LoteSituacao === 'REPASSE' &&
                                loteModal.StatusPregao === '206'
                              "
                              justify="center"
                            >
                              <div class="subheading mx-3">
                                <v-btn
                                  class="text-center"
                                  @click="
                                    DarLanceProximo(loteModal, loteModal.Botao1)
                                  "
                                  color="#EF5350"
                                  :loading="loadingBtn"
                                  :disabled="loadingBtn"
                                  ><span style="color: white">{{
                                    loteModal.Botao1
                                  }}</span></v-btn
                                >
                              </div>
                              <div class="subheading mx-3">
                                <v-btn
                                  @click="
                                    DarLanceProximo(loteModal, loteModal.Botao2)
                                  "
                                  :loading="loadingBtn"
                                  :disabled="loadingBtn"
                                  color="#D32F2F"
                                  ><span style="color: white">{{
                                    loteModal.Botao2
                                  }}</span>
                                </v-btn>
                              </div>
                              <div class="subheading mx-3">
                                <v-btn
                                  @click="
                                    DarLanceProximo(loteModal, loteModal.Botao3)
                                  "
                                  :loading="loadingBtn"
                                  :disabled="loadingBtn"
                                  color="#D32F2F"
                                  ><span style="color: white">{{
                                    loteModal.Botao3
                                  }}</span>
                                </v-btn>
                              </div>
                            </v-row>
                          </div>
                          <div v-else>
                            <v-row justify="center">
                              <div class="subheading mx-3 text-center">
                                <span style="color: white"
                                  ><h2>
                                    <v-icon color="white"
                                      >mdi-eye-settings</v-icon
                                    >&nbsp; Espectador
                                  </h2></span
                                >
                                <br />
                              </div>
                              <div v-if="loteModal.VencendoApelido">
                                <div class="subheading mx-3 text-center">
                                  <p color="#D32F2F">
                                    <span style="color: white">{{
                                      "Vencendo"
                                    }}</span>
                                  </p>
                                </div>
                                <div class="text-center">
                                  <p color="#D32F2F">
                                    <span style="color: white">{{
                                      loteModal.VencendoApelido
                                    }}</span>
                                  </p>
                                </div>
                              </div>
                            </v-row>
                          </div>
                        </div>
                        <!-- laudo cautelar -->
                        <v-btn
                          v-if="loteModal.DocumentoLaudoCautelarArquivo"
                          style="margin-top: 20px"
                          :href="loteModal.DocumentoLaudoCautelarArquivo"
                          target="_blank"
                          color="#0D47A1"
                          depressed
                          class="white--text"
                          block
                        >
                          Download do laudo cautelar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- tab de informacoes -->
            <v-tab-item key="informacoesProximo">
              <v-card
                class="mx-auto"
                flat
                :max-width="isMobile() ? '550' : 'none'"
              >
                <div v-if="dadosInfoLoteProximo">
                  <div v-if="isMobile() === true">
                    <v-card-title>
                      <h3>
                        {{ dadosInfoLoteProximo.EstoqueVeiculoMarca }}
                        {{ dadosInfoLoteProximo.EstoqueVeiculoModelo }}
                        {{ dadosInfoLoteProximo.EstoqueVeiculoAno }}/{{
                          dadosInfoLoteProximo.EstoqueVeiculoAnoMod
                        }}
                      </h3>
                    </v-card-title>
                  </div>

                  <div v-else>
                    <v-container>
                      <v-card-title class="pa-0">
                        <h3>
                          {{ dadosInfoLoteProximo.EstoqueVeiculoMarca }}
                          {{ dadosInfoLoteProximo.EstoqueVeiculoModelo }}
                          {{ dadosInfoLoteProximo.EstoqueVeiculoAno }}/{{
                            dadosInfoLoteProximo.EstoqueVeiculoAnoMod
                          }}
                        </h3>
                      </v-card-title>
                    </v-container>
                  </div>

                  <v-container fluid>
                    <p class="brade-title py-0">Informações importantes</p>
                    <v-row dense>
                      <v-col style="align-items: flex-start">
                        <v-card>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <p
                              style="
                                text-align: justify;
                                font-size: 16px;
                                color: #444444;
                              "
                            >
                              Eventual divergência no padrão de placas Mercosul
                              no físico ou no documento do veículo (ATPVe), a
                              responsabilidade pela regularização será do
                              comprador, bem como eventuais pagamentos de taxas
                              e encargos exigidos na troca de placas. Ocorrendo
                              multa de averbação na transferência de documentos,
                              esta será por conta do comprador.
                            </p>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <p class="brade-title">Visitação</p>
                    <v-row dense>
                      <v-col style="align-items: flex-start">
                        <v-card>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <!-- <p
                              style="
                                text-align: justify;
                                font-size: 16px;
                                color: #444444;
                              "
                            >
                              {{ configLeilao.Visitacao }}
                            </p> -->
                            <p
                              style="
                                text-align: justify;
                                font-size: 16px;
                                color: #444444;
                              "
                              v-html="configLeilao.Visitacao"
                            ></p>
                            <p
                              style="
                                text-align: justify;
                                font-size: 16px;
                                color: #444444;
                              "
                            >
                              Local -

                              {{
                                dadosInfoLoteProximo.EstoqueExpostoemEndereco
                              }}
                              {{
                                dadosInfoLoteProximo.EstoqueExpostoemEnderecoBairro
                              }}
                              {{ dadosInfoLoteProximo.EstoqueExpostoemCidade }}
                              -
                              {{ dadosInfoLoteProximo.EstoqueExpostoemUF }}
                              {{
                                dadosInfoLoteProximo.EstoqueExpostoemEnderecoCEP
                              }}

                              <!-- {{ dadosInfoLoteProximo.EstoqueExpostoemCidade }}
                              -
                              {{ dadosInfoLoteProximo.EstoqueExpostoemUF }}
                              {{
                                dadosInfoLoteProximo.EstoqueExpostoemEndereco
                              }}
                              {{
                                dadosInfoLoteProximo.EstoqueExpostoemEnderecoBairro
                              }}
                              {{
                                dadosInfoLoteProximo.EstoqueExpostoemEnderecoCEP
                              }} -->
                            </p>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <p class="brade-title">Forma de Pagamento</p>
                    <v-row dense>
                      <v-col style="align-items: flex-start">
                        <v-card>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <p
                              style="
                                text-align: justify;
                                font-size: 16px;
                                color: #444444;
                              "
                            >
                              Através de Transferência Eletrônica, devendo ser
                              obrigatoriamente transferido pela conta de
                              propriedade do CNPJ do lojista cadastrado. A conta
                              de crédito para pagamento será em nome da Carbuy
                              Automotive Business Ltda. Prazo para pagamento é
                              até o dia {{ configLeilao.PrazoPagamento }}.
                            </p>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <p
                      v-if="dadosInfoLoteProximo.EstoqueObservacaoVendedor"
                      class="brade-title"
                    >
                      Observações do veículo
                    </p>
                    <v-row
                      v-if="dadosInfoLoteProximo.EstoqueObservacaoVendedor"
                      dense
                    >
                      <v-col style="align-items: flex-start">
                        <v-card>
                          <div class="my-4 text-subtitle-1 brade-descricao">
                            <p
                              style="
                                text-align: justify;
                                font-size: 14px;
                                color: #444444;
                              "
                            >
                              {{
                                dadosInfoLoteProximo.EstoqueObservacaoVendedor
                              }}
                            </p>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <!-- <br /> -->
                  </v-container>

                  <!-- </v-card-text>                       -->
                </div>
                <div v-else>Não possui informações</div>
              </v-card>
            </v-tab-item>
            <!-- tab de fotos -->

            <!-- tab de video -->
            <v-tab-item key="VideoProximo">
              <v-card class="mx-auto" flat>
                <v-card-text>
                  <div
                    style="display: flex; justify-content: center"
                    v-if="loteModal.CodigoYoutube"
                    v-html="loteModal.CodigoYoutube"
                  ></div>
                  <div
                    v-else
                    style="text-align: center; margin: auto; margin-top: 50px"
                  >
                    <h3>Não possui Video</h3>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- tab de visitacao -->
            <!-- <v-tab-item key="Visitacao_virtual_proximo">
              <v-card class="mx-auto" flat>
                <v-card-text>
                  <div
                    v-if="loteModal.CodigoVirtual"
                    v-html="loteModal.CodigoVirtual"
                  ></div>
                  <div
                    v-else
                    style="text-align: center; margin: auto; margin-top: 50px"
                  >
                    <h3>Não possui visitação virtual</h3>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item> -->

            <!-- tab de fotos -->
            <v-tab-item key="FotosProximo">
              <v-card class="mx-auto" max-width="700" max-height="550" flat>
                <v-card-text>
                  <div
                    v-if="loteModal.FotosModal"
                    class="d-flex flex-no-wrap justify-space-between"
                  >
                    <p class="text-center" style="margin: auto">
                      <v-carousel style="cursor: pointer" hide-delimiters>
                        <v-carousel-item
                          @click="dialogFotos = true"
                          v-for="(img, index) in loteModal.FotosModal"
                          :key="index"
                          :src="img.FotoURL"
                          delimiter-icon="mdi-minus"
                        ></v-carousel-item>
                      </v-carousel>
                    </p>
                  </div>

                  <div
                    v-else
                    style="text-align: center; margin: auto; margin-top: 50px"
                  >
                    <h3>Não possui fotos</h3>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- tab de lances -->
            <v-tab-item key="OfertasProximo">
              <v-card flat>
                <v-card-text>
                  <v-simple-table fixed-header height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">#</th>
                          <th class="text-left">Nome</th>
                          <th class="text-left">UF</th>
                          <th class="text-left">Valor</th>
                          <th class="text-left">Data</th>
                        </tr>
                      </thead>
                      <tbody v-if="listLance.length">
                        <tr v-for="(lance, index) in listLance" :key="index">
                          <td>{{ lance.NumeroLance }}</td>
                          <td>{{ lance.Apelido }}</td>
                          <td>{{ lance.PessoaUF }}</td>
                          <td>{{ lance.ValorLance }}</td>
                          <td>{{ lance.DataLance }}</td>
                        </tr>
                      </tbody>
                      <tfoot v-else>
                        <tr>
                          <td colspan="5" style="text-align: center">
                            <h4>Nenhum item encontrado</h4>
                          </td>
                        </tr>
                      </tfoot>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-card-actions v-if="isMobile()">
            <v-btn
              :color="corPorEmpresaCardHeader(configLeilao.Empresa)"
              @click="fechaModal()"
              >Fechar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- fim modal do lote aberto -->

      <!-- dialog de fotos tela cheia -->
      <v-dialog
        v-if="dialogFotos && loteModal"
        v-model="dialogFotos"
        fullscreen
        content-class="brade-imgfull"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialogFotos = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title
              >{{ configLeilao.TermoLance }} {{ loteModal.LoteNumero }}
              {{ loteModal.Tempo }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="dialogFotos = false"> Fechar </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-carousel
              height="100%"
              v-if="loteModal.FotosModal"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
              <v-carousel-item
                v-for="(img, index) in loteModal.FotosModal"
                :key="index"
                :src="img.FotoURL"
                delimiter-icon="mdi-minus"
              ></v-carousel-item>
            </v-carousel>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- dialog de fotos tela cheia -->
    </v-container>
    <Footer :texto="rodapeTexto" screen="martelinho" />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Nav from "@/components/shared/Nav.vue";
import Footer from "@/components/shared/Footer.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import _ldsh from "lodash";
import { Money } from "v-money";
import { leilaoMixin } from "@/mixins/leilao.js";

export default {
  name: "Martelinho",
  props: {
    configuracao: {
      type: Object,
    },
  },
  components: {
    Nav,
    Money,
    Loading,
    Footer,
  },
  mixins: [leilaoMixin],
  data() {
    return {
      rodapeTexto: "",
      lotePreSelecionado: "",
      desabilitaModalEncerrado: true,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      dialogFotos: false,
      isHabilitado: true,
      usuario: "",
      header: null,
      leilao: "",
      usuarioLogadoDados: {
        codPessoaLogado: "",
        hashPessoaLogado: "",
        apelidoPessoaLogado: "",
      },
      tempoAtualDoServidor: null,
      tempoServido: "",
      loteEmDisputa: {},
      listaProximosLotes: [],
      listaLoteEncerradoVencedores: [],
      cronometroCard: null,
      configLeilao: {},
      active_tabProximo: "InicioProximo",
      dadosInfoLote: {},
      listLance: [],
      fotosLotes: [],
      loadingBtn: false,
      isTimeEncerrando: false,
      dialog: false,
      loteModal: {},
      active_tab: "Inicio",
      dadosInfoLoteProximo: {},
    };
  },
  created() {
    this.limparTodosTimesDobrowser();
    this.verificaLogin();
    this.leilao = this.$route.params.name;
    this.hash = this.$route.params.hashValmiki;
    this.usuarioLogadoDados.codPessoaLogado = this.$route.params.codCliente;
    this.usuarioLogadoDados.hashPessoaLogado = this.$route.params.hashPessoa;
    this.usuarioLogadoDados.apelidoPessoaLogado = this.$route.params.apelido;
    // this.lotePreSelecionado = this.$route.params.loteDetaque;
    this.carregarConfig(this.leilao, this.hash);

    this.carregaTempoServidor();

    this.carregaCards();

    setTimeout(() => {
      this.exibeLotePreSelecionado(this.$route.params.loteDetaque);
    }, 2800);
  },
  methods: {
    exibeLotePreSelecionado(loteInformadoViaUrl) {
      if (loteInformadoViaUrl && this.desabilitaModalEncerrado === false) {
        let lote = this.listaLoteEncerradoVencedores.find(
          (a) =>
            a.LoteCod == String(loteInformadoViaUrl) &&
            a.LoteSituacao === "ENCERRADO"
        );
        if (lote) {
          this.carregaModal(lote);
        }
      }
    },
    corPorEmpresaCardHeader(empresa) {
      let cor = "primary";
      switch (empresa?.toLowerCase()) {
        case "hastavip":
          cor = "warning";
          break;
        case "carbuy":
          cor = "error";
          break;
      }

      return cor;
    },
    carregaModal(lote) {
      if (lote) {
        this.dialog = true;

        this.playSound("audio3.mp3");
        this.loteModal = lote;
        this.active_tab = "Inicio";
        this.active_tabProximo = "InicioProximo";
        this.dadosInfoLoteProximo = {};
      } else {
        this.fechaModal();
      }
    },
    fechaModal() {
      this.dialog = false;
      this.loteModal = {};
      this.active_tab = "Inicio";
      this.active_tabProximo = "InicioProximo";
      this.dadosInfoLoteProximo = {};
      this.dialogFotos = false;
    },
    errorProcesso(info, tipo) {
      clearInterval(this.cronometroCard);
      // clearTimeout(this.msgIncrementandoTime);

      // alert(info);
      this.$toastr[tipo](info);

      return;
    },
    carregaTempoServidor() {
      this.leilao = this.$route.params.name;
      this.hash = this.$route.params.hashValmiki;
      this.usuarioLogadoDados.codPessoaLogado = this.$route.params.codCliente;
      this.usuarioLogadoDados.hashPessoaLogado = this.$route.params.hashPessoa;
      this.usuarioLogadoDados.apelidoPessoaLogado = this.$route.params.apelido;

      if (!this.leilao) {
        // alert("leilao não passado, caso o erro persista, entre contato com TI");
        this.$toastr.w(
          "leilao não passado, caso o erro persista, entre contato com TI"
        );

        console.error(
          "o paramentro leilao não existe para carregar tempo do servidor"
        );
        this.loading = false;
      }

      let timeServidorAjax = setInterval(() => {
        let baseUrlTimeServidor = `https://central.carbuy.com.br/frm3Realizacao/Json/TimeServidor.aspx?LeilaoCod=${this.leilao}&Hash=${this.hash}&PessoaCod=${this.usuarioLogadoDados.codPessoaLogado}&Apelido=${this.usuarioLogadoDados.apelidoPessoaLogado}&PessoaHash=${this.usuarioLogadoDados.hashPessoaLogado}`;
        this.$axios
          .get(`${baseUrlTimeServidor}`)
          .then((response) => {
            let retorno = response.data.Retorno;
            if (retorno.Retorno === "False") {
              // alert(retorno.Descricao);
              this.$toastr.w(retorno.Descricao);
              clearInterval(timeServidorAjax);
              this.$router.push("/");
              return;
            }

            if (response.data.TimeServidor.Reload === "True") {
              this.$toastr.w("Página será recarregada");

              this.$router.go(0);
            }

            let timeConfig = response.data.TimeServidor;
            this.infoServidor = false;
            if (timeConfig.incrementaTempo === "True") {
              this.carregaCards();

              console.log("VEIO TRUE O TIME");

              // this.loadListagem();
            }

            if (response.data.TimeServidor.Permissao === "True") {
              this.isHabilitado = true;
            } else {
              this.isHabilitado = false;
            }

            // Defina a data em que estamos fazendo a contagem regressiva baseado no tempo do servidor
            this.tempoServido = new Date(timeConfig.HoraServidor).getTime();
            //   console.log(2, this.tempoServido);
            this.tempoAtualDoServidor = timeConfig.HoraServidor;
          })
          .catch((e) => {
            console.error(
              "Excecao ao buscar dados da api para pegar dados no horario do servidor",
              e.message
            );
            this.errorProcesso("Erro no servidor", "e");
            setTimeout(() => {
              this.$router.go(0);
              clearInterval(timeServidorAjax);
            }, 4000);
          });
      }, 1000);
    },
    carregaCards() {
      let baseUrlCard = `https://central.carbuy.com.br/frm3Realizacao/Json/Lotes.aspx?LeilaoCod=${this.leilao}&Hash=${this.hash}&PessoaCod=${this.usuarioLogadoDados.codPessoaLogado}&PessoaHash=${this.usuarioLogadoDados.hashPessoaLogado}`;

      this.isTimeEncerrando = false;
      this.$axios
        .get(`${baseUrlCard}`)
        .then((response) => {
          const jsonLotes = response.data;
          if (!Array.isArray(jsonLotes.ListLotes)) {
            this.$toastr.e(jsonLotes.Retorno.Descricao);
            return;
          }
          if (!jsonLotes.ListLotes.length) {
            this.$toastr.w("Não possui lotes");
            return;
          }

          let lotesListagemOrdenado = jsonLotes.ListLotes.sort(
            (a, b) => a.LoteNumero - b.LoteNumero
          );
          let situacoesLotes = _ldsh.groupBy(
            lotesListagemOrdenado,
            (lt) => lt.LoteSituacao
          );

          if (
            !situacoesLotes.ABERTO &&
            !situacoesLotes.ENCERRADO &&
            !situacoesLotes.REPASSE // repasse so quando for carbuy
          ) {
            // this.$router.push("/");
            this.loteEmDisputa = null;
            this.$toastr.w("Evento encerrado");
            this.desabilitaModalEncerrado = false;
          }

          if (situacoesLotes.ENCERRADO) {
            this.listaLoteEncerradoVencedores = situacoesLotes.ENCERRADO.map(
              (ltE) => {
                ltE.Tempo = "00:00:00";
                ltE.MsgLote = "";
                return ltE;
              }
            );
          } else {
            this.listaLoteEncerradoVencedores = [];
          }

          if (situacoesLotes.REPASSE) {
            this.listaProximosLotes = situacoesLotes.REPASSE.map((ltE) => {
              ltE.Tempo = "00:00:00";
              ltE.MsgLote = "";
              return ltE;
            });
          } else {
            this.listaProximosLotes = [];
          }

          if (this.infoServidor) {
            console.log("servidor atualizado");
            let loteAbertosState = situacoesLotes.ABERTO;
          }

          let antigoLote = this.loteEmDisputa;

          if (situacoesLotes.ABERTO) {
            let loteProcessoDisputa = situacoesLotes.ABERTO.find(
              (l) => l.StatusPregao === "206" && l.LoteSituacao === "ABERTO"
            );

            this.loteEmDisputa = loteProcessoDisputa;
            this.desabilitaModalEncerrado = true;
            if (
              !this.loteEmDisputa &&
              this.listaProximosLotes === 0 &&
              this.listaLoteEncerradoVencedores > 0
            ) {
              this.$toastr.w("Evento encerrado");
              this.loteEmDisputa = null;
              this.listaProximosLotes = [];
            }

            let listaLotesEmDisputa = situacoesLotes.ABERTO.filter(
              (l) => l.StatusPregao === "206" && l.LoteSituacao === "ABERTO"
            );

            let listaLotesEmAbertoEAguadardando = situacoesLotes.ABERTO.filter(
              (l) => l.StatusPregao === "131" && l.LoteSituacao === "ABERTO"
            );

            if (
              listaLotesEmDisputa.length > 1 ||
              listaLotesEmAbertoEAguadardando.length > 0
            ) {
              this.$toastr.w(
                "Mais de 1 lote em disputa ou possui lote está aguardando e aberto",
                "Disputa não iniciada"
              );
              this.$router
                .push({
                  name: "Leilao",
                  params: {
                    name: this.$route.params.name,
                    hashValmiki: this.$route.params.hashValmiki,
                    codPessoaLogado: this.$route.params.codCliente,
                    hashPessoaLogado: this.$route.params.hashPessoa,
                    apelido: this.$route.params.apelido,
                    loteDetaque: this.$route.params.loteDetaque,
                  },
                })
                .catch((err) => {});

              return;
            }

            // if (!this.loteEmDisputa) {
            //   this.$toastr.w("Disputa não iniciada");
            //   this.$router
            //     .push({
            //       name: "Leilao",
            //       params: {
            //         name: this.$route.params.name,
            //         hashValmiki: this.$route.params.hashValmiki,
            //         codPessoaLogado: this.$route.params.codCliente,
            //         hashPessoaLogado: this.$route.params.hashPessoa,
            //         apelido: this.$route.params.apelido,
            //       },
            //     })
            //     .catch((err) => {});

            //   return;
            // }
          } else {
            this.$toastr.w("Evento encerrado");
            this.loteEmDisputa = null;
            this.listaProximosLotes = [];
            this.desabilitaModalEncerrado = false;

            //this.$router.go(0);
          }

          setTimeout(() => {
            this.setCronomentroTimeLotes();
          }, 1000);

          if (this.loteEmDisputa) {
            this.colocaMsgTempoExtraEmLotes(
              JSON.parse(JSON.stringify(antigoLote)),
              this.loteEmDisputa
            );
          }

          if (this.loteModal) {
            if (JSON.parse(JSON.stringify(this.loteModal)).LoteCod) {
              let itemLoteSelecioado = JSON.parse(
                JSON.stringify(this.loteModal)
              );

              this.atualizaDadosLoteSelecionadoDisputaProximo(
                this.listaProximosLotes,
                itemLoteSelecioado.LoteCod
              );
            }
          }
        })
        .catch((e) => {
          console.error(
            "Excecao ao buscar dados da api para exibir os cards ",
            e.message
          );
          this.errorProcesso("Erro no servidor", "e");
        })
        .finally(() => {
          // this.setCronomentro();
        });
    },
    setCronomentroTimeLotes() {
      let configTimeEncerrando = this.configLeilao.TempoEncerrando || "30";

      if (!this.tempoAtualDoServidor) {
        // tempo do servidor sempre deve existe p/ todos usuarios
        this.$toastr.w("Sincronizando cronômetro");

        setTimeout(() => {
          this.$router.go(0);
        }, 800);
      }

      if (this.loteEmDisputa && this.loteEmDisputa.Tempo !== "00:00:00") {
        let hd = moment(this.loteEmDisputa.TempoPadrao);
        let formatado = hd.format("DD-MM-YYYY HH:mm:ss");
        let hdTempoServidor = moment(this.tempoAtualDoServidor);
        let formatadoTempoServidor = hdTempoServidor.format(
          "DD-MM-YYYY HH:mm:ss"
        );
        var eventTime = moment(formatado, "DD-MM-YYYY HH:mm:ss").unix();

        var currentTime2 = moment(
          formatadoTempoServidor,
          "DD-MM-YYYY HH:mm:ss"
        ).unix();

        var diffTime = eventTime - currentTime2;
        var duration = moment.duration(diffTime * 1000, "milliseconds");
        let isEncerrado = hd.format("D") != hdTempoServidor.format("D");

        if (diffTime > 0) {
          duration = moment.duration(
            duration.asMilliseconds() - 1000,
            "milliseconds"
          );
          var d = moment.duration(duration).days(),
            h = moment.duration(duration).hours(),
            m = moment.duration(duration).minutes(),
            s = moment.duration(duration).seconds();

          d = d.length === 1 ? "0" + d : d;
          h = h.length === 1 ? "0" + h : h;
          m = m.length === 1 ? "0" + m : m;
          s = s.length === 1 ? "0" + s : s;

          this.loteEmDisputa.Tempo = `${d}d ${h}:${m}:${s}`;
          // this.loteEmDisputa.Progresso = Math.floor(diffTime / 60);

          let progressMinuteBar = Math.floor(diffTime / 60); // numero leva em conta o min
          this.loteEmDisputa.Progresso =
            (progressMinuteBar === 0) &
            (progressMinuteBar <= parseInt(configTimeEncerrando))
              ? parseInt(s)
              : progressMinuteBar;
          // Math.floor(diffTime / 60);

          let tempoEncerramento = `${d}d 0:0:${s}`;

          if (this.loteEmDisputa.Tempo === tempoEncerramento && d === 0) {
            if (s < configTimeEncerrando && d === 0) {
              this.loteEmDisputa.MsgLote = "TEMPO ESGOTANDO !";
              //this.playSound("audio5.mp3");
              if (s < 10) {
                this.isTimeEncerrando = true;

                this.playSound("audio5.mp3");
              }
            } else {
              this.loteEmDisputa.MsgLote = "";
            }
          } else {
            if (this.loteEmDisputa.MsgLote !== "TEMPO EXTRA!") {
              this.loteEmDisputa.MsgLote = "";
            }
          }

          if (this.loteEmDisputa.Tempo === "0d 0:0:0" && d === 0) {
            this.loteEmDisputa.Tempo = "00:00:00";
            console.log("time encerrou");
            this.loteEmDisputa.Progresso = 0;
            clearTimeout(this.cronometroCard);

            this.homologandoLoteEncerrado(
              JSON.parse(JSON.stringify(this.loteEmDisputa))
            );
            return;
          }
        } else {
          // Quer dizer tempo do lote é menor que tempo atual
          // lote.Tempo = "00:00:00";
          if (this.loteEmDisputa) {
            this.jaEncerradoLote(this.loteEmDisputa);
          }
        }
      } else {
        this.loteEmDisputa = null;
        // if (this.loteEmDisputa) {
        //   this.jaEncerradoLote(this.loteEmDisputa);
        // }
      }
      if (this.loteEmDisputa) {
        this.cronometroCard = setTimeout(() => {
          this.setCronomentroTimeLotes();
        }, 1000);
      }
    },
    playSound(audioFile) {
      var sound = new Audio(require(`@/assets/sons/${audioFile}`));

      sound.addEventListener("canplaythrough", () => {
        sound.play();
        sound.muted = false;
      });
      // sound.play();
      //  sound.muted = false;
    },
    carregarConfig(leilao, hash) {
      let urlBaseConfig = `https://central.carbuy.com.br/frm3Realizacao/Json/Config.aspx?LeilaoCod=${leilao}&Hash=${hash}&PessoaCod=${this.$route.params.codCliente}&PessoaHash=${this.$route.params.hashPessoa}`;
      this.$axios
        .get(`${urlBaseConfig}`)
        .then((response) => {
          const config = response.data;
          this.configLeilao = config.ConfigLeilao;
          this.rodapeTexto = config?.ConfigLeilao?.rodapeText;
          this.header = {
            name: this.configLeilao.Empresa,
            link: this.configLeilao.EmpresaLink,
            logoEmpresa: "",
            icon: "",
            LeilaoData: this.configLeilao.LeilaoData,
          };
        })
        .catch((e) => {
          console.error(
            "Excecao ao buscar dados da api para setar informações de configurações base do leilao ",
            e.message
          );
          this.$toastr.e("Erro no servidor");
          this.playSound("audio6.mp3");
        });
    },
    homologandoLoteEncerrado(loteEncerrado) {
      if (loteEncerrado.LoteSituacao !== "ENCERRADO") {
        let urlBaseConfig = `https://central.carbuy.com.br/frm3Realizacao/Json/Grava.aspx?Tipo=Encerrado&LoteCod=${loteEncerrado.LoteCod}&LoteHash=${loteEncerrado.LoteHash}&Hash=${this.hash}&LeilaoCod=${this.leilao}&PessoaCod=${this.usuarioLogadoDados.codPessoaLogado}&Apelido=${this.usuarioLogadoDados.apelidoPessoaLogado}&PessoaHash=${this.usuarioLogadoDados.hashPessoaLogado}`;
        let formHomologandoLote = new FormData();

        formHomologandoLote.append(
          "Json",
          JSON.stringify({
            PessoaCod: loteEncerrado.VencendoPessoaCod,
            LanceNumero: loteEncerrado.VencendoNumero,
            ValorLance: loteEncerrado.VencendoValor,
            URL: window.location.href,
            Apelido: loteEncerrado.VencendoApelido,
          })
        );
        this.$axios
          .post(`${urlBaseConfig}`, formHomologandoLote, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            const corpoRetorno = response.data.ListLotes[0];
            corpoRetorno.Tempo = loteEncerrado.Tempo;

            this.loteEmDisputa = corpoRetorno;
            this.playSound("audio1.mp3");
            this.$router.go(0);
          })
          .catch((e) => {
            console.error("Excecao ao homologar lote ", e.message);
            this.$toastr.e("Erro no servidor");
            this.playSound("audio6.mp3");
          });
      }
    },
    jaEncerradoLote(lote) {
      console.log(lote);
      lote.Tempo = "00:00:00";
      lote.MsgLote = "";
      lote.Progresso = 0;

      // console.log("ja encerrado");
      clearTimeout(this.cronometroCard);
      // força item que está status em abert mas com o tempo encerrado a ser homologado
      this.homologandoLoteEncerrado(JSON.parse(JSON.stringify(lote)));
      return;
    },
    carregaInfosLote(codigoLote, loteHash) {
      if (!codigoLote) {
        console.error(
          "Codigo lote não foi passado para buscar dados informativos do lote"
        );

        // alert("Desculpe ocorreu um erro ao buscar lances");
        this.$toastr.e("Erro no servidor");
        this.playSound("audio6.mp3");
      }
      let baseUrlInfos = `https://central.carbuy.com.br/frm3Realizacao/Json/Lote.aspx?LoteCod=${codigoLote}&Hash=${loteHash}`;

      this.$axios
        .get(`${baseUrlInfos}`)
        .then((response) => {
          let loteInfor = response.data;
          this.dadosInfoLote =
            loteInfor && Array.isArray(loteInfor) && loteInfor.length
              ? loteInfor[0]
              : {};
        })
        .catch((e) => {
          console.error(
            "Excecao ao buscar dados informativos do lote",
            e.message
          );
          // alert(
          //   "Ocorreu um erro ao buscar ao dados, caso persista entre com contato com Vip TI slz, acessar Chrome dev tools para mais informações"
          // );
          this.$toastr.e("Erro no servidor");
          this.playSound("audio6.mp3");
        })
        .finally(() => (this.loading = false));
    },
    carregaInfosLoteProximo(codigoLote, loteHash) {
      if (!codigoLote) {
        console.error(
          "Codigo lote não foi passado para buscar dados informativos do lote"
        );

        // alert("Desculpe ocorreu um erro ao buscar lances");
        this.$toastr.e("Erro no servidor");
        this.playSound("audio6.mp3");
      }
      let baseUrlInfos = `https://central.carbuy.com.br/frm3Realizacao/Json/Lote.aspx?LoteCod=${codigoLote}&Hash=${loteHash}`;

      this.$axios
        .get(`${baseUrlInfos}`)
        .then((response) => {
          let loteInfor = response.data;
          this.dadosInfoLoteProximo =
            loteInfor && Array.isArray(loteInfor) && loteInfor.length
              ? loteInfor[0]
              : {};
        })
        .catch((e) => {
          console.error(
            "Excecao ao buscar dados informativos do lote",
            e.message
          );
          // alert(
          //   "Ocorreu um erro ao buscar ao dados, caso persista entre com contato com Vip TI slz, acessar Chrome dev tools para mais informações"
          // );
          this.$toastr.e("Erro no servidor");
          this.playSound("audio6.mp3");
        })
        .finally(() => (this.loading = false));
    },
    getLances(codigoLote, loteHash) {
      if (!codigoLote) {
        console.error("Codigo lote não foi passado para buscar lances");

        // alert("Desculpe ocorreu um erro ao buscar lances");
        this.$toastr.e("Erro no servidor");
        this.playSound("audio6.mp3");
      }
      let baseUrlLances = `https://central.carbuy.com.br/frm3Realizacao/Json/Lances.aspx?LoteCod=${codigoLote}&LoteHash=${loteHash}`;
      this.loading = true;
      this.$axios
        .get(`${baseUrlLances}`)
        .then((response) => {
          const listLote = response.data;
          this.listLance = listLote.Lances;
        })
        .catch((e) => {
          console.error(
            "Excecao ao buscar dados lista de lances do lote passado",
            e.message
          );

          this.$toastr.e("Erro no servidor");
          this.playSound("audio6.mp3");
        })
        .finally(() => (this.loading = false));
    },
    DarLance(dadosLote, lanceEnviado) {
      // if (dadosLote.Tempo === "00:00:00") {
      //   this.$toastr.w("tempo lote encerrado");
      //   return;
      // }
      this.isTimeEncerrando = false;
      lanceEnviado = lanceEnviado.replace("R$ ", "");
      let urlBaseConfig = `https://central.carbuy.com.br/frm3Realizacao/Json/Grava.aspx?Tipo=Lance&LoteCod=${dadosLote.LoteCod}&LoteHash=${dadosLote.LoteHash}&Hash=${this.hash}&LeilaoCod=${this.leilao}&Processo=Disputa&PessoaCod=${this.usuarioLogadoDados.codPessoaLogado}&Apelido=${this.usuarioLogadoDados.apelidoPessoaLogado}&PessoaHash=${this.usuarioLogadoDados.hashPessoaLogado}`;
      this.loadingBtn = true;
      let formDataLance = new FormData();

      let numeroLanceVencendo =
        dadosLote.VencendoNumero !== "-"
          ? Number(dadosLote.VencendoNumero) + 1
          : 1;

      formDataLance.append(
        "json",
        JSON.stringify({
          PessoaCod: this.usuarioLogadoDados.codPessoaLogado,
          LanceNumero: numeroLanceVencendo,
          ValorLance: lanceEnviado,
          URL: window.location.href,
          Apelido: this.usuarioLogadoDados.apelidoPessoaLogado,
        })
      );
      this.$axios
        .post(`${urlBaseConfig}`, formDataLance, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const corpoRetorno = response.data.ListLotes[0];

          corpoRetorno.Tempo = dadosLote.Tempo;

          this.loteEmDisputa = corpoRetorno;

          this.playSound("audio4.mp3");
        })
        .catch((e) => {
          console.error("Excecao ao dar lance", e.message);
          this.$toastr.e("Erro no servidor");
          this.loadingBtn = false;
          this.playSound("audio6.mp3");
        })
        .finally(() => (this.loadingBtn = false));
    },
    DarLanceProximo(dadosLote, lanceEnviado) {
      if (
        dadosLote.StatusPregao !== "206" &&
        dadosLote.StatusPregao !== "REPASSE"
      ) {
        this.$toastr.w("tempo lote encerrado");
        return;
      }

      lanceEnviado = lanceEnviado.replace("R$ ", "");

      let urlBaseConfig = `https://central.carbuy.com.br/frm3Realizacao/Json/Grava.aspx?Tipo=LanceProximo&LoteCod=${dadosLote.LoteCod}&LoteHash=${dadosLote.LoteHash}&Hash=${this.hash}&LeilaoCod=${this.leilao}&Processo=Disputa&PessoaCod=${this.usuarioLogadoDados.codPessoaLogado}&Apelido=${this.usuarioLogadoDados.apelidoPessoaLogado}&PessoaHash=${this.usuarioLogadoDados.hashPessoaLogado}`;
      this.loadingBtn = true;
      let formDataLance = new FormData();

      let numeroLanceVencendo =
        dadosLote.VencendoNumero !== "-"
          ? Number(dadosLote.VencendoNumero) + 1
          : 1;

      formDataLance.append(
        "json",
        JSON.stringify({
          PessoaCod: this.usuarioLogadoDados.codPessoaLogado,
          LanceNumero: numeroLanceVencendo,
          ValorLance: lanceEnviado,
          URL: window.location.href,
          Apelido: this.usuarioLogadoDados.apelidoPessoaLogado,
        })
      );
      this.$axios
        .post(`${urlBaseConfig}`, formDataLance, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const corpoRetorno = response.data.ListLotes[0];

          corpoRetorno.Tempo = dadosLote.Tempo;

          this.loteModal = corpoRetorno;

          this.playSound("audio4.mp3");
        })
        .catch((e) => {
          console.error("Excecao ao dar lance", e.message);
          this.$toastr.e("Erro no servidor");
          this.loadingBtn = false;
          this.playSound("audio6.mp3");
        })
        .finally(() => (this.loadingBtn = false));
    },
    sairEletronico() {
      this.limparTodosTimesDobrowser();

      sessionStorage.removeItem("dados_login");

      this.$router.push({
        path: `/login/${this.leilao}`,
      });

      this.setEncerraLogin();
    },
    limparTodosTimesDobrowser() {
      let id = window.setTimeout(() => {}, 0);
      //console.log(id);
      while (id) {
        window.clearTimeout(id);
        id--;
      }

      id = window.setInterval(() => {}, 0);
      //console.log(id);
      while (id) {
        window.clearInterval(id);
        id--;
      }
    },
    verificaLogin() {
      this.usuario = this.$store.loginData
        ? this.$store.loginData.Login.Apelido
        : this.$route.params.apelido;
    },
    colocaMsgTempoExtraEmLotes(loteAntigo, novaLote) {
      // console.log("ANTIGO =>", loteAntigo, "NOVO =>", novaLote);

      if (loteAntigo.TempoPadrao && novaLote.TempoPadrao) {
        let existeLoteTempoDiferente =
          loteAntigo.TempoPadrao !== novaLote.TempoPadrao;
        if (existeLoteTempoDiferente && this.loteEmDisputa) {
          this.loteEmDisputa.MsgLote = "TEMPO EXTRA!";
          this.playSound("audio5.mp3");

          let atual = this;
          setTimeout(function () {
            atual.loteEmDisputa.MsgLote = "";
          }, 3000);
        }
      }
    },
    atualizaDadosLoteSelecionadoDisputaProximo(arrayLotes, loteSelecionaoda) {
      let loteInformado = loteSelecionaoda ?? null;
      if (loteInformado) {
        let loteSeleciodaoEmDisputa = arrayLotes.find(
          (a) => a.LoteCod == loteSelecionaoda
        ); // lotes em aberto

        //
        this.loteModal = loteSeleciodaoEmDisputa;
        // this.msgSnabar.top = true;
      }
    },
    processarLanceAutomatico(dadosLote, valor) {
      if (!["R$ 0,00", null, ""].includes(valor)) {
        let msgUsuario =
          valor === 0
            ? "Cancelado lance automático"
            : "Iniciado lance automático";

        let url = `https://central.carbuy.com.br/frm3Realizacao/Json/Grava.aspx?Tipo=Automatico&LoteCod=${dadosLote.LoteCod}&LoteHash=${dadosLote.LoteHash}&Hash=${this.hash}&LeilaoCod=${this.leilao}&PessoaCod=${this.usuarioLogadoDados.codPessoaLogado}&Apelido=${this.usuarioLogadoDados.apelidoPessoaLogado}&PessoaHash=${this.usuarioLogadoDados.hashPessoaLogado}&ValorAutomatico=${valor}`;

        let formDataLance = new FormData();

        let numeroLanceVencendo =
          dadosLote.VencendoNumero !== "-"
            ? Number(dadosLote.VencendoNumero) + 1
            : 1;

        formDataLance.append(
          "json",
          JSON.stringify({
            PessoaCod: this.usuarioLogadoDados.codPessoaLogado,
            LanceNumero: numeroLanceVencendo,
            ValorLance: valor,
            URL: window.location.href,
            Apelido: this.usuarioLogadoDados.apelidoPessoaLogado,
          })
        );
        this.$axios
          .post(`${url}`, formDataLance, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            const corpoRetorno = response.data.ListLotes[0];
            corpoRetorno.Tempo = dadosLote.Tempo;

            this.loteEmDisputa = corpoRetorno;

            this.playSound("audio4.mp3");
            this.$toastr.s(msgUsuario);
          })
          .catch((e) => {
            this.$toastr.e("Erro no servidor");
            this.loadingBtn = false;
            this.playSound("audio6.mp3");
          })
          .finally(() => (this.loadingBtn = false));
      } else {
        this.$toastr.w("Valor automatico vazio");
      }
    },
  },
};
</script>

<style lang="css" scoped>
.tocando-timer {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  -webkit-animation: shake 1.5s linear infinite;
  -moz-animation: shake 1.5s linear infinite;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.hr-text {
  background: linear-gradient(90deg, #120000 0%, #e90000 100%);
  height: 4px;
}

@media (min-width: 1360px) {
  .container {
    max-width: 1314px;
  }
}

@media (min-width: 1904px) {
  .container {
    max-width: 1785px;
  }
}

/* fim classe deve ser aplicada somente quando tiver minha classe customizada */

.v-btn--example {
  bottom: 0;
  position: absolute;
  margin: 0 0 16px 16px;
}
.box {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
}

/*banner card inicio*/
.ribbon-wrapper {
  position: static;
  z-index: 998;
}
.ribbon-front {
  background-color: #cc3333;
  height: 50px;
  width: 344px;
  margin: auto;
  position: relative;
  left: -0px;
  z-index: 2;
  font: 20px/50px bold Verdana, Geneva, sans-serif;
  color: #f8f8f8;
  text-align: center;
  text-shadow: 0px 1px 2px #cc6666;
}

.ribbon-front,
.ribbon-back-left,
.ribbon-back-right {
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
  -khtml-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
  -o-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
}

.ribbon-edge-topleft,
.ribbon-edge-topright,
.ribbon-edge-bottomleft,
.ribbon-edge-bottomright {
  position: absolute;
  z-index: 1;
  border-style: solid;
  height: 0px;
  width: 0px;
}

/* .ribbon-edge-topleft,
.ribbon-edge-topright {
  } */

.ribbon-edge-bottomleft,
.ribbon-edge-bottomright {
  top: 50px;
}

.ribbon-edge-topleft,
.ribbon-edge-bottomleft {
  left: -10px;
  border-color: transparent #9b1724 transparent transparent;
}

.ribbon-edge-topleft {
  top: -5px;
  border-width: 5px 10px 0 0;
}
.ribbon-edge-bottomleft {
  border-width: 0 10px 0px 0;
}

.ribbon-edge-topright,
.ribbon-edge-bottomright {
  left: 220px;
  border-color: transparent transparent transparent #9b1724;
}

.ribbon-edge-topright {
  top: 0px;
  border-width: 0px 0 0 10px;
}
.ribbon-edge-bottomright {
  border-width: 0 0 5px 10px;
}

@-webkit-keyframes flow {
  0% {
    left: -20px;
    opacity: 0;
  }
  50% {
    left: 100px;
    opacity: 0.3;
  }
  100% {
    left: 180px;
    opacity: 0;
  }
}
@keyframes flow {
  0% {
    left: -20px;
    opacity: 0;
  }
  50% {
    left: 100px;
    opacity: 0.3;
  }
  100% {
    left: 180px;
    opacity: 0;
  }
}

.glow {
  background: rgb(255, 255, 255);
  width: 40px;
  height: 100%;
  z-index: 200;
  position: absolute;
  -webkit-animation: flow 1.5s linear infinite;
  -moz-animation: flow 1.5s linear infinite;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(1%, rgba(255, 255, 255, 0)),
    color-stop(100%, rgba(255, 255, 255, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 1) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 1) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  border-left: 1px solid #fff;
}
/*banner card fim*/

/* edimar estilo modal */

.v-card header {
  background-color: #e5173f !important;
}

.v-card header .v-toolbar__content {
  padding: 20px;
}

.v-card header .v-toolbar__content button {
  background-color: transparent !important;
  box-shadow: none;
  padding: 0;
  min-width: 0;
  margin-left: 10px;
}

.v-card header .v-toolbar__content button i {
  font-size: 24px !important;
}

.v-card .v-tabs .v-tabs-bar {
  border-bottom: 2px solid #e5173f;
}

.v-tabs-slider {
  display: none !important;
}

.v-card .v-tabs .v-tabs-bar .v-tab {
  color: #e5173f !important;
  font-weight: bold;
  line-height: 1;
  width: 100%;
}

.v-card .v-tabs .v-tabs-bar .v-tab i {
  display: none;
}

.v-card .v-tabs .v-tabs-bar .v-tab.v-tab--active {
  background-color: #eeeeee !important;
}

.v-card .v-tabs .v-tabs-bar .v-tab.v-tab--active::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #e5173f;
  content: "";
}

.v-card .v-tabs .v-window .v-window__container .v-window-item .v-card p {
  margin: 0 !important;
  width: 100%;
}

.v-card .v-tabs .v-window .v-window__container .v-window-item .v-card p img {
  height: 320px;
  margin: 0;
  object-fit: cover;
  width: 100%;
}

.v-card .v-tabs .v-window .v-window__container .v-window-item .v-card .v-alert {
  border: 0 !important;
  box-shadow: none !important;
  padding: 0;
}

.v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .v-alert
  .v-alert__wrapper
  .v-icon {
  display: none;
}

.v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .v-alert
  .v-alert__wrapper
  .v-alert__border {
  display: none;
}

.v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .v-alert
  .v-alert__wrapper
  .v-alert__content
  h3 {
  color: #e5173f;
  font-size: 32px;
  font-weight: bold;
  padding: 5px 0;
  margin: 0 !important;
}

.v-card .v-tabs .v-window .v-window__container .v-window-item .v-card p {
  color: #7a7a7a;
  font-size: 14px;
  font-weight: normal;
  margin: 0 !important;
}

.v-card .v-tabs .v-window .v-window__container .v-window-item .v-card p span {
  font-weight: bold;
}

.v-card .v-tabs .v-window .v-window__container .v-window-item .v-card hr {
  margin: 10px 0;
  color: #7a7a7a;
}

.v-card .v-tabs .v-window .v-window__container .v-window-item .v-card .v-alert {
  margin: 0;
}

.v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .brade-right {
  background: #ca2351;
  background: linear-gradient(45deg, #e5173f 0%, #ca2351 100%);
  border-radius: 8px;
  padding: 20px 20px 12px;
}

.v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .brade-right
  p {
  color: #fff;
  font-weight: normal !important;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px !important;
}

.v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .brade-right
  .row.justify-center {
  align-items: center;
  flex-direction: column;
}

.v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .brade-right
  .row.justify-center
  button {
  background-color: #fff !important;
  box-shadow: none;
  margin-bottom: 8px;
  font-size: 16px !important;
  width: 100% !important;
}

.v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .brade-right
  .subheading {
  width: 80% !important;
}

.v-card
  .v-tabs
  .v-window
  .v-window__container
  .v-window-item
  .v-card
  .brade-right
  .row.justify-center
  button
  span {
  color: #e5173f !important;
}

.v-card .v-card__actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-card .v-card__actions button {
  background-color: #e5173f !important;
  box-shadow: none;
  color: #fff !important;
  padding: 20px 30px !important;
}

.v-card .v-tabs .v-window .v-window__container .v-window-item .v-card h3 {
  color: #e5173f;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.brade-title {
  color: #1d1d1b !important;
  text-transform: uppercase;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding-top: 20px;
  font-weight: bold !important;
}

.brade-descricao {
  margin: 0 !important;
  font-size: 14px;
  color: #7a7a7a;
}

.v-window-item .v-card {
  box-shadow: none !important;
}

.brade-col-right {
  border-left: 1px solid #a7a7a7;
  padding-left: 20px;
}

.brade-button-laudo {
  background-color: #e5173f !important;
  color: #fff !important;
  padding: 20px 30px !important;
  font-weight: normal;
  text-transform: none;
}

/* fim edimar estilo modal */

/* edimar estilo lotes */

.v-card.brade-card .v-list-item.theme--light {
  padding: 20px 20px 0;
}

.v-card.brade-card .v-list-item.theme--light .col.col-12 {
  padding: 0;
}

.v-card.brade-card .v-list-item.theme--light .col.col-12 h3.text-center {
  color: #3e3e3e !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 1;
  padding-bottom: 10px;
}

.v-card.brade-card .v-list-item.theme--light .col.col-12 p.text-center {
  color: #3e3e3e !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 1;
  margin: 0;
}

.v-card.brade-card .v-list-item.theme--light .col.col-12 p.text-center b {
  font-weight: 900 !important;
}

.v-card.brade-card .v-list-item.theme--light .col.col-12 p.text-center i {
  /* color: #e5173f !important; */
  margin-right: 5px;
}

.brade-divider {
  border-color: #e5173f !important;
  border-width: 3px !important;
  margin: 20px 0;
  max-width: 133px !important;
}

.v-card.brade-card
  .v-list-item.theme--light
  .col.col-12
  h3.subtitle-1.text-center {
  color: #3e3e3e !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  padding-bottom: 0 !important;
  text-transform: uppercase;
}

.v-card.brade-card .v-list-item.theme--light .col.col-12 h1.text-center {
  color: #e5173f !important;
  font-size: 30px !important;
  line-height: 1.2 !important;
}

.v-card.brade-card .col.col-12 {
  padding: 20px;
  padding-top: 10px;
}

.v-card.brade-card .col.col-12 .col.col-6 {
  color: #3e3e3e !important;
  font-size: 14px !important;
  line-height: 1;
  margin: 0;
  padding: 0 !important;
}

.v-card.brade-card .col.col-12 .col.col-6 i {
  color: #e5173f !important;
  margin-left: 0 !important;
  margin-right: 5px;
}

.v-card.brade-card .v-card__actions {
  padding: 0 20px 20px;
}

.v-card.brade-card .v-card__actions p.caption.text-left {
  align-items: center;
  border: 3px solid #e5173f !important;
  border-radius: 20px;
  color: #3e3e3e !important;
  display: flex;
  font-size: 11px !important;
  font-weight: 900 !important;
  justify-content: center;
  line-height: 1;
  margin: 0;
  min-width: 100px;
  padding: 8px 16px;
}

/* fim edimar estilo lotes */
</style>